/**
 * Created by haluk on 8/3/17.
 */



//import LogRocket from 'logrocket';

export default {
  data() {
    return {
      searchTerm: '',
      isProfileMenuShown: false,
      isMessagesMenuShown: false,
      isNotificationsMenuShown: false,
      conversations: [],
      chatOpenLoading: false,
      chatOpenLoadingOnBottom: false,
      seemorediv: false,
      conversationScrollHeight: 20,
      conversationScrollHeightMax: 300,
      chatOffset: 0,
      notifications: [],
      notificationOpenLoading: false,
      notificationOpenLoadingOnBottom: false,
      seemoreNotif: false,
      notificationOffset: 0,
      notificationScrollHeight: 20,
      notificationScrollHeightMax: 300
    }
  },

  methods: {
    searchGigs(searchTerm, clickedFrom) {
      if(clickedFrom){
        this.$store.state.clickedFrom = clickedFrom;
      }
      this.searchResultDone = false;
      let url = encodeURI(`/search?term=${encodeURIComponent(searchTerm.toLocaleLowerCase('tr-TR'))}`)
      this.$router.push(url);
    },

    logout() {
      this.api.user.logout(this.$Progress).then(({data}) => {
        let result = data;
        if(result.success) {

          //LogRocket.identify();

          //window.Intercom('shutdown');
          amplitude.regenerateDeviceId();
          amplitude.setUserId(null);
          localStorage.removeItem("token");
          localStorage.removeItem("visitor_id");
          window.location.href = "/"
        } else {
          this.$toasted.global.errorToast({description: result.message});
        }
      }).catch(err => {
        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
      });
    },

    profileMenuClick() {
      this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'nav profile menu'});
      this.isProfileMenuShown = true;
      this.isMessagesMenuShown = false;
      this.isNotificationsMenuShown = false;
    },


    getConversations(limit, clicked) {
      this.api.workstation.getConversations('all', '', limit, this.chatOffset)
        .then(({data}) => {
          let result = data;
          this.chatOpenLoading = false;
          this.chatOpenLoadingOnBottom = false;

          if (result.success) {
            if(clicked) {
              let totalLength = result.data.conversations.length;
              let rowHeight = 76;
              let actualHeight = totalLength * rowHeight;
              this.conversationScrollHeight = ((actualHeight > this.conversationScrollHeightMax) ? this.conversationScrollHeightMax : actualHeight)
            }
            this.conversations = this.conversations.concat(result.data.conversations);
            this.seemorediv = result.data.seemorediv;
            this.chatOffset += 3;
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    },


    getConversationsByClick() {
      if (!this.isMessagesMenuShown) {
        this.isMessagesMenuShown = true;
        this.isProfileMenuShown = false;
        this.isNotificationsMenuShown = false;
        this.chatOpenLoading = true;
        this.chatOffset = 0;
        this.conversations = [];
        this.conversationScrollHeight = 20;
        this.getConversations(5, true);
      } else {
        this.isMessagesMenuShown = false;
      }
    },

    getConversationsByScroll(event) {
      if(!this.chatOpenLoadingOnBottom && this.seemorediv && (event.srcElement.offsetHeight + event.srcElement.scrollTop > event.srcElement.scrollHeight-2)) {
        this.chatOpenLoadingOnBottom = true;
        this.getConversations(3);
      }
    },

    retrieveNotifications(limit, clicked) {
      this.api.user.retrieveNotifications(limit, this.notificationOffset)
        .then(({data}) => {
          let result = data;
          this.notificationOpenLoading = false;
          this.notificationOpenLoadingOnBottom = false;

          if (result.success) {
            if(clicked) {
              let totalLength = result.data.notifications.length;
              let rowHeight = 68;
              let actualHeight = totalLength * rowHeight;
              this.notificationScrollHeight = ((actualHeight > this.notificationScrollHeightMax) ? this.notificationScrollHeightMax : actualHeight)
            }
            this.notifications = this.notifications.concat(result.data.notifications);
            this.seemoreNotif = result.data.seemorediv;
            this.notificationOffset += 1;
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    },

    retrieveNotificationsByClick() {
      if (!this.isNotificationsMenuShown) {
        this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'nav notification icon'});
        this.isNotificationsMenuShown = true;
        this.isMessagesMenuShown = false;
        this.isProfileMenuShown = false;
        this.notificationOpenLoading = true;

        this.notificationOffset = 0;
        this.notifications = [];
        this.notificationScrollHeight = 20;
        this.retrieveNotifications(5, true);
      } else {
        this.isNotificationsMenuShown = false;
      }
    },

    retrieveNotificationsByScroll(event) {
      if(!this.notificationOpenLoadingOnBottom && this.seemoreNotif && (event.srcElement.offsetHeight + event.srcElement.scrollTop > event.srcElement.scrollHeight-2)) {
        this.notificationOpenLoadingOnBottom = true;
        this.retrieveNotifications(3);
      }
    },
  },

  created() {

    this.EventBus.$off('equalizationForSearchTerm');

    this.EventBus.$on('equalizationForSearchTerm', searchTerm => {
      this.searchTerm = searchTerm;
    });
  },

  destroyed() {
    this.EventBus.$off('equalizationForSearchTerm');

  }

}
