<template>
  <div :style="($store.state.route.fullPath === '/bugun') ? 'background: #f4f5f7; height:110px; ' : 'background: #f4f5f7;'">
    <div style='position: absolute;width: calc(50% - 570px); height: 110px; background-color: white; z-index: 0;'></div>
    <div style="position: relative; margin:0 auto; width: 1140px!important; text-align: center">
      <div style='position: absolute; width: 220px; height: 110px; background-color: white; z-index: 0;'></div>
    </div>


    <div :style="($store.state.route.fullPath === '/bugun') ? 'z-index: 99; position: fixed; width: 100%; ' : 'z-index: 99; position:relative'">
      <div class="v_logged_in_nonav"
           :style="{'box-shadow': isLoggedIn ? 'none' : routerParams.length || (route && route.params[0] === '/blog') || (route && route.params[0] === '/destek') ? '': '0 5px 10px 0 rgba(0, 0, 0, 0.1)'}">
        <div style=' left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
        <div class="container">
          <div class="mainHeader">
            <div class="headerContainerFlex">
              <div class="headerContainerFlex" style="float: left;">
                <router-link :to="prefixPath" replace>
                  <img @click="scrollTopOfToPage()" class="mainLogo" src="https://gcdn.bionluk.com/site/general/bionluk_logo_gk.png">
                </router-link>
              </div>

              <div id="regularHeaderSearchContainer" class="headerContainerFlex" :style="!$store.state.user.seller.isSeller && $store.state.route.fullPath === '/bugun' ? 'visibility:hidden' : 'visibility:visible; opacity:1'">
                <input id="main-search"
                       v-model="searchTerm"
                       @keyup.enter="inputEnter(searchTerm)"
                       @keyup="keyUp()"
                       @keydown="keyDown()"
                       type="text"
                       name="search"
                       class="mainSearch"
                       autocomplete="off"
                       data-lpignore="true"
                      placeholder="Arama yap...">
                <loader v-if="isSearching" style="position: absolute; right: 0;height: 25px; width: 25px;"></loader>
                <div class="search-result-container" v-if="categorySearchResults && searchResultDone" v-click-away="() =>  searchResultDone = false">
                  <!--categories-->
                  <div class="search-result-header">
                    <p style="font-size: 14px;color: #737878;"><span style="font-weight: 500; color: #2d3640;">Kategoriler</span>de Ara</p>
                  </div>
                  <div v-if="categorySearchResults.length > 0" class="search-result-users-container" style="padding-left: 38px;">
                    <div class="search-result-user"
                         @click="routeSearchDeedsPage(categorySearchResult, searchTerm)"
                         style="cursor:pointer; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; margin-bottom: 10px;"
                         v-for="categorySearchResult in categorySearchResults">
                      <p style="font-size: 14px;line-height: 2.29;text-align: left;color: #2d3640;">{{categorySearchResult.name}}<span style="color: #737878;"> kategorisinde "{{searchTerm}}"</span>
                      </p>
                    </div>
                  </div>
                  <!--users-->
                  <div class="search-result-header">
                    <p style="font-size: 14px;color: #737878;"><span style="font-weight: 500; color: #2d3640;">Freelancerlar</span>da Ara</p>
                    <div
                      @click="routeSearchUserPage(searchTerm)"
                                 style="display: flex; align-items: center; border: solid 1px #2d3640; padding:  8px 19px;cursor: pointer">
                      <p style="font-size: 12px;  color: #2d3640;">Tüm Freelancerlarda Ara</p>
                    </div>
                  </div>
                  <div v-if="userSearchResults.length > 0" class="search-result-users-container">
                    <div class="search-result-user"
                         @click="goToUserProfile(userSearchResult.username)"
                         style="cursor:pointer; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; margin-bottom: 10px;"
                         v-for="userSearchResult in userSearchResults">
                      <img style="width: 36px; height: 36px; border-radius: 50%; margin-right: 14px;"
                           :src="userSearchResult.avatarURL"/>
                      <p style="font-size: 14px;line-height: 2.29;text-align: left;color: #2d3640;">{{userSearchResult.username}}</p>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="isLoggedIn" style="margin-right:40px; width: 140px;" class="headerContainerFlex">

                <template>
                  <ul>
                    <li class="posrelative" style="height: 22px;">
                      <router-link :to="'/panel/hatirlatma'"
                                   @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                                   @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')">
                        <span v-if="user.todoCount" style="cursor: pointer;" class="button-badge todo_count">{{ user.todoCount }}</span>
                        <img style="height:20px;margin-left:32px"
                             :src="$root.CDN_SITE + 'icon/' + (user.todoCount ? 'ic_assignment.svg' : 'ic_assignment_turned_in_gray.svg')">
                      </router-link>
                    </li>
                  </ul>

                  <ul>
                    <li class="posrelative" style="height: 22px;">
                      <div @click="redirectToMessages()">
                      <span v-if="user.unreadMessageCount" style="cursor: pointer;" class="button-badge unread_message_count">{{ user.unreadMessageCount
                        }}</span>
                        <img
                          style="cursor: pointer;height:22px;margin-left:32px"
                          :src="'https://gcdn.bionluk.com/site/icon/aws/' + (user.unreadMessageCount ? 'ic_commentt.svg' : 'ic_commentt_gray.svg')">
                      </div>

                      <div v-if="isMessagesMenuShown" class="dropdown-content-messages messagebox" v-click-away="() => isMessagesMenuShown = false">
                        <div v-if="!conversations.length && !chatOpenLoading" class="no-content">
                          <div>Mesaj Bulunamadı</div>
                        </div>
                        <div class="with-content">
                          <div style="overflow-y:scroll;" @scroll="getConversationsByScroll($event)">
                            <div :style="'overflow-y: scroll; height:'+ conversationScrollHeight - 10 +'px;'">
                              <template v-for="conversation in conversations">
                                <div
                                  tag="div"
                                  @click="openChatBox(conversation)"
                                  :class="{ notifitemrow: true, active: !conversation.isseen }"
                                  style=" cursor:pointer;display: flex; align-items: center; text-align: left; height: 40px;"
                                  v-show="!chatOpenLoading">
                                  <div style="flex: 1;">
                                    <img class="message_avatar circularavatar" style="width: 32px; height: 32px; " :src="conversation.r_avatar_url">
                                  </div>
                                  <div style="flex: 428; margin-left: 15px;">
                                    <div :class="{ notifiteminfo: true, active: conversation.isseen }">
                                      {{ new Date(conversation.last_message_created_at) | customDateFormat }}
                                    </div>

                                    <div :class="{ notifitem: true, active: conversation.isseen }">{{ conversation.r_username }}</div>
                                    <div :class="{ notifitemdesc: true, active: conversation.isseen }">
                                      {{ ((conversation.message.length > 82) ? conversation.message.slice(0, 80) + '...' : conversation.message) }}
                                    </div>

                                  </div>
                                </div>
                              </template>
                            </div>
                            <div v-show="chatOpenLoadingOnBottom || chatOpenLoading" class="loader"
                                 style="text-align: center; margin: 20px auto; height: 20px; width: 20px;"></div>
                          </div>

                          <div class="linefull"></div>
                          <router-link v-if="conversations[0]" :to="'/chat/'+ conversations[0].r_username" tag="div" class="seemoremessages"
                                       style="cursor:pointer; margin: 8px auto; color: #fd4056;"
                                       @click.native="isMessagesMenuShown = false">
                            + Tümünü Göster
                          </router-link>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <ul>
                    <li class="posrelative" style="height: 22px;">
                      <div @click="retrieveNotificationsByClick()">
                        <img style="cursor: pointer;height:21px;margin-left:32px"
                             :src="'https://gcdn.bionluk.com/site/icon/aws/' + (user.unreadNotificationCount ? 'ic_zil.svg' : 'ic_zil_gray.svg')">
                        <span v-if="user.unreadNotificationCount"
                              style="cursor: pointer;" class="button-badge notification_count">
                        {{ user.unreadNotificationCount }}
                      </span>
                      </div>
                      <div v-if="isNotificationsMenuShown" class="dropdown-content-messages" v-click-away="() =>  isNotificationsMenuShown = false">
                        <div v-if="!notifications.length && !notificationOpenLoading" class="no-content">
                          <div>Henüz bir bildirimin yok.</div>
                        </div>
                        <div class="with-content">
                          <!--  <div :style="'overflow-y: scroll; height:'+ notificationScrollHeight +'px;'" @scroll="retrieveNotificationsByScroll($event)"> -->
                          <template v-for="notification in notifications">
                            <div @click="routeNotification(notification)" style="cursor: pointer;"
                                         class="notifitemrow">
                              <div style="width: 10px;">
                                <div v-if="!notification.read_at">
                                  <div class="red-dot"></div>
                                </div>
                              </div>
                              <div style="flex: 1; margin-left: 10px;">
                                <template v-if="notification.image_type === 'image'">
                                  <img style="height: 38px!important; width: 38px; border-radius: 50%; border: 1px solid #eff3f8;" class="notificon" :src="notification.image_url">
                                </template>
                                <template v-else>
                                  <div class="icon" :style="'background-color:'+notification.icon_background_color">
                                    <img :src="notification.icon" onload="SVGInject(this)" class="icon-svg">
                                  </div>
                                </template>
                              </div>
                              <div style="flex: 428; margin-left: 10px;">
                                <div style="display: flex; justify-content: space-between">
                                  <div class="notifitem" style=""> {{ notification.title }}</div>
                                  <div class="notifiteminfo" style="">{{ notification.since_short }}</div>
                                </div>
                                <div class="notifitemdesc">{{ notification.text }} <span v-if="notification.bold_text">{{notification.bold_text}}</span></div>
                              </div>
                            </div>
                          </template>
                          <div v-show="notificationOpenLoadingOnBottom || notificationOpenLoading" class="loader"
                               style="text-align: center; margin: 10px auto; height: 20px; width: 20px;"></div>


                          <div  @click="routeSeeAllNotifications"   style="cursor:pointer; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: 600; color:#2d3640;  width: 350px;height: 40px;border-radius: 5px;box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07);background-color: #f4f5f7;">
                            Tümünü Göster
                          </div>

                        </div>
                      </div>
                    </li>
                  </ul>
                </template>
              </div>
              <div style="float: right; " class="headerContainerFlex">
                <div v-if="isLoggedIn">
                  <ul>

                    <li style="cursor: pointer" class="posrelative">
                    <span class="headerContainerFlex" style="width:220px; border-left:1px solid #eaedf2;  border-right: 1px solid #eaedf2;position: relative"
                          @click="profileMenuClick()">
                      <img style="height:32px; width:32px; border-radius: 50%; margin-left:15px;" :src=user.avatar_url>
                      <span class="avatarname">{{ user.username }}</span>
                      <img style="position: absolute;height: 9px;right: 30px;top: 50%;transform: translate(0%,-50%);"
                           src="https://gcdn.bionluk.com/site/icon/aws/icon_altok_gri.svg">
                    </span>
                      <div v-if="isProfileMenuShown" class="dropdown-content-profile-menu"
                           v-click-away="() => isProfileMenuShown = false"
                          @click="isProfileMenuShown = !isProfileMenuShown"
                      >

                        <div style="display: flex; align-items: center; margin-bottom: 10px;">
                          <img :src="user.avatar_url" class="menu-item-avatar">
                          <div class="menu-item-user-info">
                            <p class="name-lastname">{{user.firstname}} {{user.lastname}}</p>
                            <p class="email">{{user.email}}</p>
                          </div>
                        </div>

                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                          class="menu-item-body"  :to="`/${user.username}`">
                          <img src="https://gcdn.bionluk.com/site/c/ic-myprofile.svg" class="menu-item-img">
                          <p class="menu-item">Profilim</p>
                        </router-link>

                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                          class="menu-item-body"  to="/settings">
                          <img src="https://gcdn.bionluk.com/site/c/ic-settings.svg" class="menu-item-img">
                          <p class="menu-item">Hesabım</p>
                        </router-link>


                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                          class="menu-item-body"  to="/destek">
                          <img src="https://gcdn.bionluk.com/site/c/ic-support2.svg" class="menu-item-img">
                          <p class="menu-item">Destek</p>
                        </router-link>

                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                          class="menu-item-body" v-if="!user.seller.isSeller" to="/freelancer-olmak">
                          <img src="https://gcdn.bionluk.com/site/c/ic-container.svg" class="menu-item-img">
                          <p class="menu-item">Freelancer Ol</p>
                        </router-link>

                        <a class="menu-item-body" href="javascript:void(0)" @click="logout()">
                          <img src="https://gcdn.bionluk.com/site/c/ic-logout.svg" class="menu-item-img" style="margin-bottom: -2px;">
                          <p class="menu-item">Oturumu Kapat</p>
                        </a>

                      </div>
                    </li>

                  </ul>
                </div>
                <div v-else class="no-hover-underline" style="display: flex; flex-direction: row; align-items: center">
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                    :to="'/freelancer-bul'">
                  <span class="explore">Keşfet
                  </span>
                  </router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                    :to="'/login'">
                    <span class="login-link">Giriş Yap</span>
                  </router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                    :to="'/register'">
                    <button class="super-button signin-button">Kayıt Ol</button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!($store.state.route.fullPath === '/freelancer-bul' ||
      $store.state.route.fullPath === '/freelancer-olmak' ||
      $store.state.route.fullPath === '/pro' ||
      $store.state.route.fullPath === '/bicoin' ||
      $store.state.route.fullPath.split('/')[1] === 'go')"
        style=" z-index: 10; box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);width: 100%; background-color: white; ">
        <div class="container">
          <div class="mainNav">
            <div class="mainNav-inner">
              <div class="mainNav-category-body" v-for="(category, index) in parentCategories"
                   @mouseover="mouseOver(category.name)" @mouseout="mouseOut(category.name)">
                <router-link  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"  @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')" :key="index" :to="'/freelancer-bul/' + category.slug" class="category-container">
                  {{category.name}}
                </router-link>
                <div :id="category.name" class="sub-category-container"
                     :style="index < parentCategories.length/2 ? 'left: 10px;' : 'right: 10px;'">
                  <!--<div style="width: 0;height: 0;border-left: 6px solid transparent;border-right: 6px solid transparent;border-bottom: 9px solid  #2d3640; align-self: flex-start; margin-top:-38px"/>-->
                  <ul class="sub-category" style="margin-right: 30px;">
                    <router-link
                                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                                  @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                                  class="list"
                                  v-if="index%2 === 0"
                                  :style="`width: ${calculateWidestText(subCategory.name, subCategory.is_new)}px;`"
                                  v-for="(subCategory, index) in category.subCategory"
                                  :key="'left' + index"
                                  :to="goToCategory(category.name, subCategory.slug, true)"
                                  >
                      {{subCategory.name}}<span v-if="subCategory.is_new" class="is-new"><span class="is-new-t">Yeni</span></span>
                    </router-link>
                  </ul>
                  <ul class="sub-category">
                    <router-link
                                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'nav')"
                                @click.native="handleGlobalClick($store.state.previousComponentPage,'nav')"
                                class="list"
                                 v-if="index%2 !== 0"
                                 :style="`width: ${calculateWidestText(subCategory.name, subCategory.is_new)}px;`"
                                 v-for="(subCategory, index) in category.subCategory"
                                 :key="'right' + index"
                                 :to="goToCategory(category.name, subCategory.slug, true)"
                                 >
                      {{subCategory.name}}<span v-if="subCategory.is_new" class="is-new"><span class="is-new-t">Yeni</span></span>
                    </router-link>
                  </ul>
                </div>
              </div>


            </div>

          </div>
        </div>


      </div>


    </div>

    <div class="dummy" style="position: absolute;z-index: -999; opacity:0">
      <img src="https://gcdn.bionluk.com/site/cicons/ic-bi.svg" onload="SVGInject(this)" >
      <img src="https://gcdn.bionluk.com/site/cicons/ic-no-internet.svg" onload="SVGInject(this)">
      <img src="https://gcdn.bionluk.com/site/cicons/ic-ihaveinternet.svg" onload="SVGInject(this)" >
    </div>

  </div>

</template>

<script>
  import mixin from './mixin';

  export default {
    mixins: [mixin],
    name: "src-pages-header-shared-regular",
    data() {
      return {
        userSearchResults: [],
        categorySearchResults: [],
        isSearching: false,
        searchResultDone: false,
        active: false,
        typingTimer: null
      }
    },
    methods: {

	    redirectToMessages(){

		    this.api.workstation.getConversations('all', '', 1, 0)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    if(result.data.conversations && result.data.conversations[0]){
						    this.$store.state.clickedFrom = 'nav';
					    	let path = '/chat/'+ result.data.conversations[0].r_username;
						    this.$router.push(path);
              } else {
                this.$store.state.clickedFrom = 'nav';
                let path = '/panel/mesajlar';
                this.$router.push(path);
              }
				    } else {
              this.$store.state.clickedFrom = 'nav';
              let path = '/panel/mesajlar';
              this.$router.push(path);
				    }
			    })
			    .catch(err => {
				    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			    });

      },

      //alttaki 3 function klavyeden 200 ms boyunca birşey girilmezse sonuç gösterebilsin diye yazıldı
      keyUp() {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => this.getAzureResult(), 200);
      },

      keyDown() {
        clearTimeout(this.typingTimer);
      },

      async getAzureResult() {
        let searchValue = this.searchTerm.trim().replace(/ +/g, "");
        if (2 < searchValue.length && searchValue.length < 20) {
          this.isSearching = true;
          this.searchResultDone = true;
          await this.$store.state.categoriesPromise

          this.searchUsers({term: searchValue})
            .then(result => {
              let results = result.results;
              if (results) {
                let arr = [];
                results.forEach(newResult => {
                  if (arr.length < 7) {
                    arr.push(newResult);
                  }
                });
                this.userSearchResults = arr;
                this.isSearching = false;
              }
            })
            .catch(err => {
              this.userSearchResults = [];
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });

          this.searchDeeds2({term: this.searchTerm.trim().toLocaleLowerCase('tr-TR'), limit: 1, facets: ["subCategorySlug"]})
            .then((results) => {
              if (results.facets && results.facets.subCategorySlug.length) {
                let facetedResults = results.facets.subCategorySlug.sort(function (a, b) {
                  return b.count - a.count;
                });

                facetedResults.forEach(result => {
                  this.$store.state.categories.forEach(category => {
                    if (category.slug === result.value) {
                      result.name = category.name;
                      result.isParent = !category.parent_id;
                      result.id = category.id;
                    }
                  });
                });

                this.categorySearchResults = facetedResults.slice(0, 4);
              }
            })
            .catch(err => {
              this.categorySearchResults = [];
              this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            });
        } else {
          this.userSearchResults = [];
          this.categorySearchResults = [];
          this.isSearching = false;
          this.searchResultDone = false;
        }
      },

      scrollTopOfToPage() {
        clearInterval(this.interval);
        let pos = window.scrollY;
        let temp = window.scrollY;
        this.interval = setInterval(() => {

          if (pos <= 0) {
            clearInterval(this.interval);
          } else {
            if (window.scrollY > pos || window.scrollY < pos) {
              clearInterval(this.interval);
            } else {
              if (temp - pos > 5000) {
                window.scrollTo(0, 0);
              } else {
                pos = pos - 100; // bu sayı artarsa daha hızlı scroll olacaktır.
                window.scrollTo(0, pos);
              }
            }
          }
        }, 10);
      },

      goToUserProfile(username) {
        this.$router.push(`/${username}`);
        this.searchResultDone = false;
      },

      goToCategory(categoryName, url, isHref = false) {
        if (!isHref) {
          let subCategories = document.getElementById(categoryName);
          subCategories.style.display = "none";
        }
        if (url && isHref) {
          return '/freelancer-bul/' + url;
        }
      },

      routeSearchDeedsPage(searchedCategory, term) {
	      this.$store.state.clickedFrom = 'nav';
	      let url = encodeURI(`/search?term=${encodeURIComponent(term.toLocaleLowerCase('tr-TR'))}&slug=${searchedCategory.value}`)
        this.$router.push(url);
        this.searchResultDone = false;
      },

	    routeSearchUserPage(term) {
		    this.$store.state.clickedFrom = 'nav';
		    this.$router.push(`/usersearch?term=${term.toLocaleLowerCase('tr-TR')}`);
		    this.searchResultDone = false;
	    },

      routeNotification(notification){
	      this.$store.state.clickedFrom = 'nav notifications';
        this.api.user.readNotification(notification.id);
	      this.$router.push(notification.target_url);
        this.EventBus.$emit('reloadOrderPage', 'reload')
	      this.isNotificationsMenuShown = false
      },
	    routeSeeAllNotifications() {
		    this.$store.state.clickedFrom = 'nav';
		    this.$router.push('/feed/notifications');
        this.EventBus.$emit('reloadNotificationPage', 'reload')
		    this.isNotificationsMenuShown = false
	    },

      mouseOver(categoryName) {
        let subCategories = document.getElementById(categoryName);
        subCategories.style.display = "flex";
      },

      mouseOut(categoryName) {
        let subCategories = document.getElementById(categoryName);
        subCategories.style.display = "none";
      },

      inputEnter(searchTerm) {
        this.searchGigs(searchTerm, 'nav enter');
      },

      getTextWidth(text, fontSize, fontFace) {
        let a = document.createElement('canvas');
        let b = a.getContext('2d');
        b.font = fontSize + 'px ' + fontFace;
        return Number((b.measureText(text).width).toFixed(1));
      },
      calculateWidestText(name, is_new=false) {
        // let longest = arr.reduce(function (a, b) {
        //   return a.length > b.length ? a : b;
        // });

        let add = is_new ? 60 : 0;
        return this.getTextWidth(name, 15, "sofia-pro") + add;
      },

      openChatBox(conversation) {
        if (this.$route.fullPath.includes("/chat/")) {
          this.$router.push("/chat/" + conversation.r_username);
        } else {
          conversation.fromWhere = "Header Notification Bar";
          this.EventBus.$emit('externalOpenChat', conversation);
        }
        this.isMessagesMenuShown = false;
      }
    },
    watch:{
      '$store.state.route.fullPath': function (newValue, oldValue) {
        if(newValue){
          clearTimeout(this.typingTimer);
          this.isSearching = false;
          this.searchResultDone = false;
          this.categorySearchResults = [];
          this.userSearchResults = [];
        }
      }

    },

    created() {
      this.EventBus.$on('dashboardSearch', (payload) => {
        this.searchTerm = payload.searchTerm;
      })
    },

    beforeDestroy() {
      this.EventBus.$off('dashboardSearch');
    }
  }

</script>

<style scoped lang="scss">


.is-new{
  width: 35px;
  height: 16px;
  margin-left:5px;
  border-radius: 5px;
  background-color: #fd4056;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  display: flex;
  .is-new-t{
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.22px;
    text-align: center;
    color: #fff;
  }
}

  #regularHeaderSearchContainer{
    float: right; margin-right: 78px; margin-left:auto; position: relative;
    opacity: 1;
    transition: 0.5s;
  }
  .search-result-container {
    width: 480px;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 47.5px;
    display: flex;
    flex-direction: column;
    left: 0;
  }

  .search-result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 19px;
    height: 30px;
    max-width: 451px;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
    text-align: left;
    color: #2d3640;
  }

  .search-result-users-container {
    max-width: 442px;
    padding: 15px 19px 5px 19px;
    background-color: #f4f5f7;
  }

  .search-result-user:hover {
    text-decoration: underline;
  }

  .notifitem {

    font-size: 14px;
    font-weight: 600;
    color: #8b95a1;

  }

  .notifitem.active {

  }

  .notifitemdesc {
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #2d3640;
    span{
      font-weight: 600;
      color: #00a575;
    }
  }

  .notifiteminfo {
    font-size: 11px;
    color: #8b95a1;
    font-weight: 400;

  }

  .nav_tab {
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    color: #8b95a1;
    height: 50px;
    flex: 1;
    border-right: 1px solid #eaedf2;
    align-content: center;
    align-items: center;
    display: flex;
  }

  .nav_tab:hover {
    color: #fff;
    border-bottom: 3px solid #2d3640;
    background-color: #2d3640;
    height: 50px;
    flex: 1;
    border-right: 1px solid #eaedf2;
    align-content: center;
    align-items: center;
    display: flex;
  }

  .nav_tab.router-link-active {
    color: #fff;
    border-bottom: 3px solid #2d3640;
    background-color: #2d3640;
    height: 50px;
    flex: 1;
    border-right: 1px solid #eaedf2;
    align-content: center;
    align-items: center;
    display: flex;
  }

  .v_logged_in_nonav {
    position: relative;

  }

  .container {
    min-width: 1170px;
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
  }

  .mainHeader {
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    background: #ffffff;
    position: relative;
  }

  .headerContainerFlex {
    align-items: center;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex;

    -webkit-box-align: center;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;

    height: 60px;
  }

  .no-hover-underline {
    font-weight: 300;
  }

  .no-hover-underline a:hover {
    text-decoration: none;
  }

  .mainLogo {
    height: 29px;
    width: 120px;
    margin-left: 38px;
    margin-top: 6px;
  }

  .block {
    height: 70px;
    width: 40px;
    margin-left: 20px;
    float: right;
    position: relative;
  }

  .avatarname {
    font-size: 14px;
    line-height: 1.29;
    color: #2d3640;
    margin-left: 15px;
  }

  input[type="text"].mainSearch {
    width: 480px;
    background: #ffffff url("https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg") no-repeat 2.5% center;
    text-align: left;
    border: 0;
    border-bottom: 1px solid #bfc8d2;
    padding: 8px 36px 8px 48px;
    margin-bottom: 6px;
    font-size: 18px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &::-webkit-input-placeholder { /* Edge */
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #bfc8d2;
      padding-top: 4px;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #bfc8d2;
      padding-top: 4px;
    }

    &::placeholder {
      font-size: 15px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #bfc8d2;
      padding-top: 4px;
    }
  }

  input[type="text"].mainSearch:focus {
    outline: none;
  }

  .aline {
    margin-top: 10px;
    margin-left: -10px;
    margin-right: -20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaedf2
  }

  .notifline {
    width: 259px;
    height: 1px;
    border-bottom: solid 1px #eaedf2;
    margin: 10px -7px;
  }

  .linefull {
    border-bottom: solid 1px #eaedf2;
  }

  .dropdown-content-profile-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 35px 0px 35px;
    position: absolute;
    background-color: #ffffff;
    width: 263px;
    box-shadow: 0 0px 10px 0 rgba(45, 54, 64, 0.2);
    z-index: 2;
    top: 55px;
    right: 0;
    margin-left: -3px;
    border: 1px solid rgba(94, 107, 121, 0.1);
    border-radius: 2px;
  }

  .dropdown-content-profile-menu::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 30px;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .menu-item-body {
    display: flex;
    align-items: center;
    padding-left: 13px;
    padding-top: 18px;
    padding-bottom: 18px;
    text-decoration: none;

    &:hover {
      .menu-item-img {
        opacity: 1;
      }

      .menu-item {
        color: #2d3640;
      }
    }

    .menu-item-img {
      height: 18px;
      width: auto;
      margin-right: 10px;
      opacity: .4;
    }

    .menu-item {
      color: #959aa8;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .menu-item-avatar {
    box-sizing: border-box;
    border: 2px solid #eaeaea;
    border-radius: 100%;
    width: 44px;
    height: 44px;
  }

  .menu-item-user-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .name-lastname {
      font-weight: 500;
      font-size: 14px;
      color: #2d3640;
      text-align: left;
    }

    .email {
      font-weight: 400;
      font-size: 12px;
      color: #8b95a1;
      margin-top: 2px;
      text-align: left;
      max-width: 150px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }


  .linkbecome {
    color: #2d3640 !important;
    font-weight: 600 !important;
  }

  .dropdown-content a:hover {
    color: #2d3640;
    font-weight: 500;
  }

  .workstationMenu {
    cursor: default;
    display: block;
    position: absolute;
    background-color: #ffffff;
    width: 480px;
    height: 196px;
    background-color: #ffffff;
    z-index: 999;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
    top: 68px;
    border-radius: 4px;
    font-size: 14px;
    color: #8b95a1;
    margin: 0 auto !important;
  }

  .workstationMenu:after, .workstationMenu:before {

    left: 77%;

  }

  .workstationMenu:after, .workstationMenu:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
  }

  .workstationMenu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 12px;
    margin-left: -12px;

  }

  .workstationMenu:before {
    border-color: rgba(161, 161, 161, 0);

    border-width: 14px;
    margin-left: -14px;

  }

  .workstationMenu a {

    font-size: 14px;
    line-height: 2.29;
    color: #737878;
  }

  .dropdown-content-messages {
    cursor: default;
    display: block;
    position: absolute;


    z-index: 2;
    top: 38px;
    margin-left: -290px;



    font-size: 14px;
    color: #8b95a1;


    width: 350px;

    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.2);
    border: solid 1px rgba(94, 107, 121, 0.1);
    background-color: #ffffff;

    .icon-svg /deep/ {
      width: 16px;
      height: 16px;
      path {
        fill: #fff;
      }
    }

    .img{
      margin-left: 20px;
      border-radius: 50%;
      height: 38px; width: 38px; border:1px solid #eff3f8;
      margin-right: 10px;
    }
    .icon{
      height: 38px; width: 38px; border:1px solid #eff3f8;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }



  }

  .dropdown-content-messages.messagebox {

    margin-left: -250px;

  }

  .dropdown-content-messages.messagebox:after, .dropdown-content-messages.messagebox:before {

    left: 77%;

  }

  .dropdown-content-messages:after, .dropdown-content-messages:before {
    bottom: 100%;
    left: 94%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 3px;
    position: absolute;
    pointer-events: none;
  }

  .dropdown-content-messages:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 10px;
    margin-left: -10px;

  }

  .dropdown-content-messages:before {
    border-color: rgba(161, 161, 161, 0);
    border-bottom-color: rgba(94, 107, 121, 0.1);
    border-width: 12px;
    margin-left: -12px;



  }

  .dropdown-content-messages .no-content {

    padding-top: 25px;
    padding-bottom: 25px;

  }

  .button-badge {
    background: #fd4056;
    border: 3px solid #ffffff;
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    position: absolute;
    top: -12px;
    left: 18px;
    font-weight: 800;

    width: 17px;
    height: 17px;
    line-height: 14px;

  }

  .notifitemrow {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 15px 20px 15px 10px;
    border-bottom: solid 1px #bfc8d2;
  }

  .notifitemrow:hover {
    text-decoration: none;
    background-color: #F5F6F8;
  }

  .notifitemrow.active {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 15px 20px;
    background-color: #dde3eb;
  }

  .notifitemrow.active:hover {
    text-decoration: none;
    background-color: #eaedf2;
  }

  .seemorenotif {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #2d3640;
    margin-top: 20px;
  }

  .seemorenotif:hover {
    text-decoration: underline;
  }

  .notificon {
    width: 32px;
    height: 32px;
  }

  .red-dot{
    background: #fd4056; width: 10px; height: 10px; border-radius: 50%;
  }

  .seemoremessages:hover {
    text-decoration: underline;
  }

  /* üst menu css'leri*/

  .mainNav {
    max-width: 1440px;
    width: 100%;
    background: #ffffff;
    position: relative;
    text-align: center;
    margin: 0 auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    .mainNav-inner {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      align-self: center;
      flex-direction: row;

      .mainNav-category-body {
        display: flex;
        justify-content: center;
        position: relative;
      }
    }
  }

  .category-container {
    cursor: pointer;
    font-size: 15px;
    color: #8b95a1;
    line-height: 18px;
    position: relative;
    padding: 14px 15px;
  }

  .category-container:hover {
    text-decoration: none;
    font-size: 15px;
    text-align: center;
    color: #2d3640;
    position: relative;
  }

  /*altçizgi*/
  .category-container:hover::after {
    content: '';
    position: absolute;
    /*paddingleri çıkarmak zorundayım*/
    width: calc(100% - 30px);
    top: -2px;
    left: 15px;
    right: 0;
    bottom: -2px;
    border-bottom: 3px solid black;
  }

  /* link aktifken*/
  .category-container.router-link-exact-active {
    text-decoration: none;
    font-size: 15px;
    /*400 üstü'ne çekersen hover yapılınca yazılar oynuyor*/
    font-weight: 400 !important;
    text-align: center;
    color: #2d3640;
    position: relative;
  }

  .sub-category-container {

    display: none;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    padding: 29px 29px 22px 29px;
    top: 48px;
    flex-direction: row;
  }

  .sub-category {
    font-size: 14px;
    line-height: 2;
    text-align: left;
    color: #8b95a1;
    display: flex;
    flex-direction: column;
  }

  .list {
    margin-bottom: 10px;
    color: #8b95a1;
    display: flex;
    align-items: center;

  }

  .list:nth-child(odd) {
  }

  .list:hover {
    text-decoration: none;
    font-size: 14px;
    color: #2d3640;
    cursor: pointer;
  }

  /*header login - become freelancer buton*/
  .signin-button {
    width: 150px;
    min-width: 150px;
    height: 44px;
    border-radius: 2px;
    background-color: #26ca9a;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    margin-right: 15px;

    &:hover {
      background-color: #00a575 !important;
    }
  }

  .login-link {
    font-size: 16px;
    color: #4b4f52;
    padding-right: 40px;
  }

  .login-link:hover {
    color: #2d3640;
    text-decoration: none !important;
  }

  .explore {
    font-size: 16px;
    color: #4b4f52;
    padding-right: 40px;
  }

  .explore:hover {
    color: #2d3640;
    text-decoration: none !important;
  }

  .router-link-active {
    font-weight: 600 !important;
  }

  .v_logged_in_nonav {
    border-bottom: 1px solid #eaedf2;
    z-index: 99;
    width: 100%;
    background-color: white;
  }

</style>
